'use strict';
var novicell = novicell || {};

novicell.owlCarousel = novicell.owlCarousel || function () {

	function init() {
		if ( $('.js-carousel').length ) {
			owlCarousel();
		}
		if ( $('.js-product-carousel').length ) {
			productCarousel();
		}
	}

	function owlCarousel() {
	    $('.js-carousel').owlCarousel({
	        items: 1,
	        dots: false,
	        nav: true,
	        loop: false,
	        navSpeed: 500,
	        margin: 30,
	        responsive: {
	            768:{
	            	margin: 30,
	                items: 2
	            },
	            992:{
	            	margin: 40,
	                items: 3
	            },
	            1200:{
	            	margin: 60,
	                items: 3
	            },
	            1600:{
	            	margin: 80,
	                items: 3
	            }
	        },
	        navText:    ["<svg class='icon'><use xmlns:xlink='http://www.w3.org/1999/xlink' xlink:href='/Files/Templates/Designs/nortec2018/__dist/icons/icons.svg#slider-arrow-left'></use></svg>"
	                    ,"<svg class='icon'><use xmlns:xlink='http://www.w3.org/1999/xlink' xlink:href='/Files/Templates/Designs/nortec2018/__dist/icons/icons.svg#slider-arrow-right'></use></svg>"]
	    });
	}

	function productCarousel() {
		var $product_carousel = $('.js-product-carousel'),
			$product_carousel_item = $('.product-item', $product_carousel);

		if ( $product_carousel_item.length > 0 ) {

			$product_carousel_item.each(function() {
				$(this).parent().addClass('col--xs-12');
			});

			$product_carousel.owlCarousel({
				items: 1,
		        dots: false,
		        nav: true,
		        loop: false,
		        navSpeed: 500,
		        responsive: {
		            768:{
		                items: 2
		            },
		            992:{
		                items: 3
		            },
		            1200:{
		            	items: 4
		            }
		        },
		        navText:    ["<svg class='icon'><use xmlns:xlink='http://www.w3.org/1999/xlink' xlink:href='/Files/Templates/Designs/nortec2018/__dist/icons/icons.svg#slider-arrow-left'></use></svg>"
	                    	,"<svg class='icon'><use xmlns:xlink='http://www.w3.org/1999/xlink' xlink:href='/Files/Templates/Designs/nortec2018/__dist/icons/icons.svg#slider-arrow-right'></use></svg>"]
			});
		} else {

			$product_carousel_item.each(function() {
				$(this).parent().addClass('col--xs-12 col--sm-6 col--md-4 col--xl-3');
			});
			
		}
	}

	return {
		init : init,
		owlCarousel : owlCarousel,
		productCarousel : productCarousel
	};

}();
