'use strict';
/*
* Novicell master
*  Author: APN modified by DLA
*  Use this file to call functions in other files, on document ready, window.resize etc.
*  ------
* Dependencies: jquery, novicell.js, other files with awesome functions.
*  Usage: $(function () {
*              projectName.slider.heroSlider($('.owl-carousel'));
*         }); // Document ready end
*/

var novicell = novicell || {};
var projectName = projectName || {};

// Document ready
(function ($) {
    // Only include Novicell functions that you use!

    // Call new functions here like this:
    svg4everybody(); // Fix SVG spritemap in IE/Edge

    /*
    *  Use the following if needed
    */

    novicell.siteHeader.init();
    novicell.lazyLoad.init();
    novicell.owlCarousel.init();
    novicell.magnificPopups.init();
    novicell.technicalData.init();
    novicell.custom.init();
    novicell.accordion.init();

    if ( !$('.site-header__bottom').length ) {
        var topnav_width = $('#topnavigation').outerWidth();
        $('#topnavigation').css({
            'width': topnav_width + 'px'
        });
    }

    // Create frontpage hero scroll animation
    $(window).on('load scroll', function () {
        if ( $('.paragraph-hero-banner').length ) {
            var scrollTop = $(window).scrollTop(),
                height = $(window).height(),
                height_half = height / 1.5;

            $('.paragraph-hero-banner__bg-embed').css({
                'opacity': ((height_half - scrollTop) / height_half)
            });

            $('.paragraph-hero-banner .center-container').css({
                'transform': 'translateY(-'+(scrollTop / 8)+'px)',
                'opacity': ((height - scrollTop) / height)
            });
        }
    });


    // Create scroll animation for anchorlinks
    $('a').on('click', function(e) {
        var link = $(this).attr('href');
        if (link.indexOf('#') > -1) {
            var elementId = link.substring(link.indexOf('#'), link.length);
            var element = $(elementId);
            if (element.length) {
                e.preventDefault();

                $('html, body').animate({
                    scrollTop: $(elementId).offset().top
                }, 750);
            }
        }
    });
    // if ( $('.js-scroll-anchorlink').length ) {
    //     $('.js-scroll-anchorlink').on('click', function(e) {
    //         e.preventDefault();
    //         var scrollTop;

    //         if ( this.classList.contains('scroll-animation') ) {

    //             scrollTop = 0;

    //             $('html, body').animate({
    //                 scrollTop: $($.attr(this, 'href')).offset().top - scrollTop
    //             }, 750);
    //         } else {
    //             if ($(window).width() > 767 ) {
    //                 scrollTop = 190;
    //             } else {
    //                 scrollTop = 170;
    //             }

    //             if ( document.documentElement ) {
    //                 document.documentElement.scrollTop = $($.attr(this, 'href')).offset().top - scrollTop
    //             } else {
    //                 document.body.scrollTop = $($.attr(this, 'href')).offset().top - scrollTop
    //             }
    //         }

    //     });
    // }



    if ( $('.js-section-animation').length ) {
        function listenerSection(event, watcher) {
            if (!watcher.isInViewport) {
                return;
            } else if (watcher.isInViewport) {
                watcher.watchItem.classList.add('show');
            }
        }
        $('.js-section-animation').each(function (i, section) {
            var watcher = scrollMonitor.create( section, -50 );
            watcher.stateChange(listenerSection);
            listenerSection(null, watcher);
        });
    }

    if ( $('.js-image-animation').length ) {
        function listenerImage(event, watcher) {
            if (!watcher.isInViewport) {
                return;
            } else if (watcher.isFullyInViewport ) {
                watcher.watchItem.classList.add('show');
            }
        }
        $('.js-image-animation').each(function (i, element) {
            var watcher = scrollMonitor.create( element );
            watcher.stateChange(listenerImage);
            listenerImage(null, watcher);
        });
    }


    // Get URL parameters
    function getUrlParam(name) {
        var results = new RegExp('[\\?&]' + name + '=([^&#]*)').exec(window.location.href);
        return (results && results[1]) || undefined;
    }

    var formular        = $('#contact-section'),
        formularParam   = getUrlParam('pid');

    // If formular parameter exists in the URL, then scroll to the formular
    if ( formularParam && formular ) {
        var scrollTop = 90;

        $('html, body').animate({
            scrollTop: formular.offset().top - scrollTop
        }, 750);
    }


    

    // if ( $('#page-content').hasClass('page-content--frontpage') ) {
    //     var $hero = $('.paragraph-hero-banner'),
    //         $hero_inner = $('.paragraph-hero-banner__inner'),
    //         $hero_height = $hero.outerHeight(),
    //         $her_height_percentage = ($hero_height / 100),
    //         $page_content = $('#page-content > section');

    //     $(window).scroll( function() {
    //         var $scrollTop = $(window).scrollTop();

            
    //     });
    // }

    // Window load
    // $(window).load(function(e){
    //     // call functions here
    // }); // Window load


})(jQuery); // Document ready end


