'use strict';
var novicell = novicell || {};

novicell.lazyLoad = novicell.lazyLoad || function () {

	function init() {
		$(window).on('load', function() {
			lazy_load_images();
		});
	}

	function lazy_load_images() {
	    var lazy_image = document.querySelectorAll('img.js-lazy-load');

	    if ( lazy_image ) {
	    	for (var i = 0; i < lazy_image.length; i++) { 
	    	    makeWatcher(lazy_image[i]);
	    	}
	    }

	    function viewportWatcher() {
	    	if (!this.isInViewport) {
	    		return;
	    	} else {
	    		setImgSrc(this.watchItem);
	    	}
	    }

	    function setImgSrc(element) {
	    	var element_img = element.getAttribute('data-src');

	    	if ( !element.classList.contains('img-loaded') ) {
		    	element.src = element_img;

		    	element.addEventListener('load', function() {
		    		element.classList.add('img-loaded');
		    	});
	    	}
	    }

	    function makeWatcher( element ) {
	    	var watcher = scrollMonitor.create( element, 200 );
	    	watcher.stateChange(viewportWatcher);
	    	viewportWatcher.call( watcher );
	    }
	}

	return {
		init : init,
		lazy_load_images : lazy_load_images
	};

}();