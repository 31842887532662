'use strict';
var novicell = novicell || {};

novicell.siteHeader = novicell.siteHeader || function () {

	function init() {
		stickyHeader();
		searchToggle();
		navigationTrigger();
	}

	function stickyHeader() {
		var siteHeader = $('.js-site-header'),
			siteHeader_bottom = $('.site-header__bottom', siteHeader),
			hero_banner = $('.paragraph-hero-banner');

		//set scrolling variables
		var scrolling = false,
			previousTop = 0,
			currentTop = 0,
			scrollDelta = 10,
			scrollOffset = 150;

		$(window).on('scroll load', function(){
			if( !scrolling ) {
				scrolling = true;

				(!window.requestAnimationFrame)
					? setTimeout(autoHideHeader, 250)
					: requestAnimationFrame(autoHideHeader);
			}
		});

		function autoHideHeader() {
			var currentTop = $(window).scrollTop();

			if ( hero_banner.length > 0 ) {
				navHero(currentTop) // Banner hero exists
			} else if ( siteHeader_bottom.length ) {
				navInclSubNav(currentTop) // Subnav exists
			} else {
				navSimple(currentTop)
			}

		   	previousTop = currentTop;
			scrolling = false;
		}

		// Subnav exists
		function navInclSubNav(currentTop) {
			//there's no secondary nav or secondary nav is below primary nav
		    if (previousTop - currentTop > scrollDelta) {
		    	//if scrolling up...
		    	siteHeader.removeClass('site-header--scrolled is-hidden');
		    } else if( currentTop - previousTop > scrollDelta && currentTop > scrollOffset) {
		    	//if scrolling down...
		    	siteHeader.addClass('site-header--scrolled is-hidden');
		    }
		}

		// Banner hero exists
		function navHero(currentTop) {
			var currentTop = $(window).scrollTop();

			( currentTop > (hero_banner.outerHeight() - 173) )
				? siteHeader.addClass('site-header--scrolled')
				: siteHeader.removeClass('site-header--scrolled');
		}

		// Simple nav
		function navSimple(currentTop) {
			var currentTop = $(window).scrollTop();

			( currentTop > siteHeader.outerHeight() )
				? siteHeader.addClass('site-header--scrolled')
				: siteHeader.removeClass('site-header--scrolled');
		}
	}

	function searchToggle() {
		$('.js-site-search-toggle').on('click', function(e) {
		    e.stopPropagation();
		    if ( $(window).width() < 768 ) {
		        var $site_header = $('.js-site-header');
		        
		        if ( $site_header.hasClass('js-search-open') ) {
		            $site_header.removeClass('js-search-open');
		        } else {
		            $site_header.addClass('js-search-open');
		            $('.js-typeahead-search-field').focus();
		        }
		    }
		});

		$('.js-site-search-close').on('click', function(e) {
		    e.stopPropagation();
		    if ( $('.js-site-header').hasClass('js-search-open') ) {
		        $('.js-site-header').removeClass('js-search-open');
		    }
		});
	}

	function navigationTrigger() {
		$('.js-navigation-trigger').on('click', function (e) {
		    e.stopPropagation();
		    var $site_navigation = $('.js-navigation-overlay'),
		        $site_navigation_items = $('.navigation-overlay__primary-navigation ul > .menu__item, .navigation-overlay__secondary-navigation ul > .menu__item', $site_navigation),
		        i = 0;

		    if ( !$site_navigation.hasClass('js-open') ) {
		    	$('html').addClass('no-scroll');
		        $site_navigation.addClass('js-open');
		        $('.js-navigation-trigger').addClass('js-open');
		        $('.js-site-header').addClass('js-nav-open');

		        $site_navigation_items.each(function() {
		            var _this = $(this);
		            setTimeout(function() {
		                _this.addClass("show");
		            }, i*45);
		            i++;
		        });
		        i = 0;

		    } else {
		        $site_navigation.removeClass('js-open');
		        $('.js-navigation-trigger').removeClass('js-open');
		        $('.js-site-header').removeClass('js-nav-open');

		        $site_navigation_items.each(function() {
		            var _this = $(this);
		            setTimeout(function() {
		                _this.removeClass("show");
		            }, i*45);
		            i++;
		        });
		        i = 0;

		        $('html').removeClass('no-scroll');
		    }
		});
	}

	return {
		init : init,
		stickyHeader : stickyHeader,
		searchToggle : searchToggle,
		navigationTrigger : navigationTrigger
	};

}();