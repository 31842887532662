'use strict';
var novicell = novicell || {};

novicell.magnificPopups = novicell.magnificPopups || function () {

	function init() {
		magnificPopups();
	}

	function magnificPopups() {
	    if ( $('.js-image-gallery').length ) {
	        $('.js-image-gallery').each(function() {
	            $(this).magnificPopup({
	                delegate: 'figure',
	                type: 'image',
	                gallery: {
	                    enabled: true,
	                    navigateByImgClick: true
	                },
	                tLoading: 'Loader..'
	            });
	        });
	    }

	    if ( $('.js-magnific-popup-single').length ) {
	        $('.js-magnific-popup-single').magnificPopup({
	            type: 'image',
	            closeOnContentClick: true,
	            iframe: {
	              patterns: {
	                youtube: {
	                  index: 'youtube.com/', // String that detects type of video (in this case YouTube). Simply via url.indexOf(index).

	                  id: 'v=', // String that splits URL in a two parts, second part should be %id%
	                  // Or null - full URL will be returned
	                  // Or a function that should return %id%, for example:
	                  // id: function(url) { return 'parsed id'; }

	                  src: '//www.youtube.com/embed/%id%?autoplay=1' // URL that will be set as a source for iframe.
	                },
	                vimeo: {
	                  index: 'vimeo.com/',
	                  id: '/',
	                  src: '//player.vimeo.com/video/%id%?autoplay=1'
	                }

	                // you may add here more sources

	              },

	              srcAction: 'iframe_src', // Templating object key. First part defines CSS selector, second attribute. "iframe_src" means: find "iframe" and set attribute "src".
	            },
	            callbacks: {
	              elementParse: function(item) {

	                if (item.el.hasClass('video-popup-link')) { 
	                    item.type = 'iframe';
	                } else {
	                    item.type = 'image';
	                }

	              }
	            }
	        });
	    }
	}

	return {
		init : init,
		magnificPopups : magnificPopups
	};

}();