'use strict';
var novicell = novicell || {};

novicell.technicalData = novicell.technicalData || function () {

	function init() {
		if ( $('.js-data-open-trigger') && $('.js-technical-data').length ) {
			technicalData_Toggle();
		}
	}

	function technicalData_Toggle() {
		var $tech_data = $('.js-technical-data'),
			$tech_item = $('.technical-data__item', $tech_data),
			$i = 0,
			$tech_overlay = $('.js-technical-data');

		$('.js-data-open-trigger').on('click', function() {
			$tech_overlay.addClass('js-active');

			setTimeout(function() {
				$tech_item.each(function() {
				    var _this = $(this);
				    setTimeout(function() {
				        _this.addClass("show");
				    }, $i*30);
				    $i++;
				});
				$i = 0;
			}, 300);
		});

		$('.js-data-close-trigger').on('click', function() {
			$tech_overlay.removeClass('js-active');

			$tech_item.each(function() {
			    var _this = $(this);
			    setTimeout(function() {
			        _this.removeClass("show");
			    }, $i*35);
			    $i++;
			});
		});

		$i = 0;
	}

	return {
		init : init,
		technicalData_Toggle : technicalData_Toggle
	};

}();