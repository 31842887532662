'use strict';
var novicell = novicell || {};

novicell.accordion = novicell.accordion || function () {

	function init() {
		var accordions = document.querySelectorAll('.product-feature');

		if ( accordions ) {
			accordionFunction(accordions);
		}
	}

	function accordionFunction(accordions) {
		for ( var i = 0; i < accordions.length; i++ ) {
			var input = accordions[i].querySelector('.product-feature__input');

			input.addEventListener('change', function(e) {
				e.stopPropagation();

				var this_accordion = this.parentNode.parentNode,
					content = this_accordion.querySelector('.product-feature__content');

				if ( this.checked == true ) {
					content.style.height = '100%';
					var content_height = content.scrollHeight + 'px';
					content.style.height = '0px';

					this_accordion.classList.add('active');
					setTimeout(function() {
						content.style.height = content_height;
					}, 0);
				} else {
					content.style.height = '0px';
					this_accordion.classList.remove('active');
				}
			});
		} 
	}

	return {
		init : init
	};

}();