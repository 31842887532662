'use strict';
var novicell = novicell || {};

novicell.custom = novicell.custom || function () {
    function init() {
        product_from();
    }
    function product_from()
    {
        $("form.productcontactform").submit(function (e) {
            e.preventDefault();
            var data = $(this).serialize();
            $.post("/default.aspx?id=2208", data, function (response) {
                var table = $(response).find(".table_formtable").html();
                $("#productcontactform").html(table);
            });
        });
    }

    $("body").on('click','a.add-to-catalog', function (e) {
        e.preventDefault();
        Loader();
        var $this = $(this);
        var productID = $(this).data('product-id')
        var url = $(this).attr('href');
        console.log(url)
        if (url.includes('Default.aspx'))
        {
            var url = "/" + $(this).attr('href');
        }
        var jsonurl = "/Default.aspx?ID=2334&productid=" + productID;
        var stat = $(this).data("is-favorited");
        var call = $.post(url, function (response) {
            var minicart = $(response).find("div.catalog-link").html();
            $("div.catalog-link").html(minicart);
        });

        call.always(function () {
            $.getJSON(jsonurl, function (json) {
                console.log(json)
                $this.attr('href', json[0].addToCartLink);
                $this.attr("data-is-favorited", json[0].productInCart)
                $this.attr("data-incart", json[0].productInCart)
                //var prod = getproductByid(json[0].ProductsContainer, productID)
                //$this.attr('href', prod[0].Product[0].addToCartLink);
                //$this.attr("data-is-favorited", prod[0].Product[0].productInCart)
                //$this.attr("data-incart", prod[0].Product[0].productInCart)
            }).done(function () {
                removeLoader();
                novicell.lazyLoad.lazy_load_images();
            });
        });
    });
    $(document).ready(function() {
        var iframes = $('.paragraph__text iframe');
        $.each(iframes, function (i, val) {
            var height = $(val).attr('height');
            var width = $(val).attr('width');
            $(val).css({
                'height': height + 'px',
                'width': width + 'px'
            });
        });
    });
    function Loader(event) {
            var overlayElement = document.createElement('div');
            overlayElement.className = dynamoConfig.preloaderOverlayClass;
            overlayElement.setAttribute('id', "overlay");
            var overlayElementIcon = document.createElement('div');
            overlayElementIcon.className = "spinner";
            var bounce1 = document.createElement('div');
            bounce1.className = "bounce1";
            var bounce2 = document.createElement('div');
            bounce2.className = "bounce2";
            var bounce3 = document.createElement('div');
            bounce3.className = "bounce3";
            overlayElementIcon.appendChild(bounce1);
            overlayElementIcon.appendChild(bounce2);
            overlayElementIcon.appendChild(bounce3);
            
            //overlayElementIcon.style.top = window.pageYOffset + "px";
            overlayElement.appendChild(overlayElementIcon);
            if (document.getElementById(dynamoConfig.preloaderOverlayLocationID)) {
                document.getElementById(dynamoConfig.preloaderOverlayLocationID).parentNode.insertBefore(overlayElement, document.getElementById(dynamoConfig.preloaderOverlayLocationID));
            }
    }
    function removeLoader()
    {
        if (document.getElementById('overlay')) {
            document.getElementById('overlay').parentNode.removeChild(document.getElementById('overlay'));
        }
    }
    function getproductByid(data, product) {
        return data.filter(
            function (data) { return data.id == product }
        );
    }
    

    return {
        init: init,
        product_from: product_from
    };
}();
